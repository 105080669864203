import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePrefs } from 'wsm-common-data';
import DropdownItem from './DropdownItem';

const DropdownMenu = ({ subnavItems }) => {
	const { subnavClasses } = usePrefs();
	const navsV2 = useSelector((state) => state.widgetData.navsV2);

	const getDropdownMenuClassesAndAttributes = () => {
		const attrToReturn = {};
		const blankSubNavClasses = subnavClasses.toLowerCase() === 'blank';
		attrToReturn.className = blankSubNavClasses ? '' : subnavClasses;

		// this replicates a known cms-web bug on line 245 of htdocs/v9/widgets/navigation/default/v1/widget.vm
		// where dropdown-menu is only set if subNavClaseses are not present
		// navsV2 is currently managed by a feature flag and relates to whether or not a variation is version 9.2 or 9.3
		// set here: cms-web/blob/master/htdocs/v9/variations/version-config.xml
		if (navsV2 && !blankSubNavClasses) {
			attrToReturn.className =
				attrToReturn.className.concat(' dropdown-menu');
		}

		return attrToReturn;
	};

	const dropdownMenuAttrs = getDropdownMenuClassesAndAttributes();

	return (
		<ul {...dropdownMenuAttrs}>
			{subnavItems.map((item) => (
				<DropdownItem
					url={item.url}
					target={item.target}
					labelAlias={item.labelAlias}
					enabled={item?.state !== 'disabled'}
					key={`${item.labelAlias}-${item.urlAlias}-${item.target}`}
				/>
			))}
		</ul>
	);
};

DropdownMenu.propTypes = {
	subnavItems: PropTypes.arrayOf({
		pageAlias: PropTypes.string,
		target: PropTypes.string
	}).isRequired
};

export default DropdownMenu;
