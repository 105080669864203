import React from 'react';
import PropTypes from 'prop-types';
import HeaderLink from './HeaderLink';
import MMColumnSection from './MMColumnSection';

const MMColumn = ({
	navigationItems,
	gridUnits,
	labelAlias,
	topMenuItemIndex
}) => {
	return (
		<li key={labelAlias} className={`ddc-span${gridUnits}`}>
			{navigationItems?.map((parent, i) => {
				const key = `${parent.label}-${topMenuItemIndex}-${i}`;
				if (parent.navigationItems.length === 0) {
					return (
						<HeaderLink
							url={parent.url}
							headerClasses="parent"
							target={parent.target}
							additionalAttrs={{
								'data-navigation-id': 'default'
							}}
							key={key}
						>
							{parent.label}
						</HeaderLink>
					);
				} else {
					return (
						<MMColumnSection
							itemsData={parent.navigationItems}
							headerLabel={parent.label}
							topMenuItemIndex={topMenuItemIndex}
							key={key}
						/>
					);
				}
			})}
		</li>
	);
};

MMColumn.propTypes = {
	navigationItems: PropTypes.arrayOf.isRequired,
	gridUnits: PropTypes.number.isRequired,
	labelAlias: PropTypes.string.isRequired,
	topMenuItemIndex: PropTypes.number.isRequired
};

export default MMColumn;
