import React from 'react';
import PropTypes from 'prop-types';
import NavLink from './NavLink';

const HeaderLink = ({
	url = '',
	headerClasses = '',
	target = '',
	additionalAttrs = {},
	children
}) => {
	return (
		<h4 className={headerClasses}>
			<NavLink
				url={url}
				target={target}
				additionalAttrs={additionalAttrs}
			>
				{children}
			</NavLink>
		</h4>
	);
};

HeaderLink.propTypes = {
	additionalAttrs: PropTypes.oneOfType([PropTypes.object]),
	children: PropTypes.string.isRequired,
	headerClasses: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
};

export default HeaderLink;
