import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'ws-scripts/modules/common';

const MMColumnItem = ({ headerLabel, children }) => {
	const labels = useLabels();
	return (
		<>
			<h4>{labels.getHTML(headerLabel)}</h4>
			{children}
		</>
	);
};

MMColumnItem.propTypes = {
	headerLabel: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default MMColumnItem;
